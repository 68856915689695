import React, { useState } from 'react';
import { Row, Col, Form, Select, Tag, Input, InputNumber, Button, Card } from 'antd';
import { responseCodes } from "../../components/backend/backend";
import { BreadcrumbsRequestLogs } from "../../components/breadcrumbs/breadcrumbsStat";
import { DateAndTime, GetDefaultAfter, GetDefaultBefore } from "../../components/dateAndTime";
import { GetUserSelect } from "../../components/getUsers";
import { RequestLogsListTable } from "../../subpages/stat/logRequests";
import { MonitorOutlined } from "@ant-design/icons";
import { Helmet } from 'react-helmet';

export const RequestLogsList = (props) => {
    const [refreshData, setRefreshData] = useState(0);

    const [minResponseSize, setMinResponseSize] = useState(0);
    const [minRequestSize, setMinRequestSize] = useState(0);
    const [minDuration, setMinDuration] = useState(0);
    const [responseCode, setResponseCode] = useState(0);
    const [method, setMethod] = useState("");
    const [remoteAddr, setRemoteAddr] = useState("");
    const [endpoint, setEndpoint] = useState("");
    const [userID, setAuthorUser] = useState("");
    const [sessionID, setSessionID] = useState("");
    const [before, setBefore] = useState(GetDefaultBefore());
    const [after, setAfter] = useState(GetDefaultAfter());
    const [total, setTotal] = useState(0);

    return (
        <>
            <Helmet>
                <title>Request Logs || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsRequestLogs />
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Card style={{ marginTop: "1em" }}>
                    <Form layout="vertical">
                        <Row gutter={[16, 16]}>
                            <Col xs={24} xl={12}>
                                <Form.Item label="Dates" name="Dates"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select Before and after',
                                        },
                                    ]}>
                                    <DateAndTime setBefore={setBefore} setAfter={setAfter} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} xl={12}>
                                Total: <Tag color={"purple"}>{total}</Tag>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} xl={8}>
                                <Form.Item
                                    name={`User`}
                                    label={`User`}
                                >
                                    <GetUserSelect grants={props.grants} setUser={setAuthorUser} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} xl={8}>
                                <Form.Item
                                    name={`Endpoint`}
                                    label={`Endpoint`}
                                >
                                    <Input onChange={(e) => setEndpoint(e.target.value)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} xl={8}>
                                <Form.Item
                                    name={`Response code`}
                                    label={`Response code`}
                                >
                                    <Select
                                        onChange={(value) => setResponseCode(value)}
                                        options={responseCodes}
                                        fieldNames={{ label: "name", value: "code" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} xl={8}>
                                <Form.Item name="min_duration" label="Min. duration (ms)">
                                    <InputNumber min={0} defaultValue={0} onChange={(e) => setMinDuration(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} xl={8}>
                                <Form.Item name="min_request_size" label="Min. req. size (bytes)">
                                    <InputNumber min={0} defaultValue={0} onChange={(e) => setMinRequestSize(e)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} xl={8}>
                                <Form.Item name="min_response_size" label="Min. resp. size (bytes)">
                                    <InputNumber min={0} defaultValue={0} onChange={(e) => setMinResponseSize(e)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} xl={8}>
                                <Form.Item name="remote_addr" label="IP Address">
                                    <Input onChange={(e) => setRemoteAddr(e.target.value)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} xl={8}>
                                <Form.Item name="session_id" label="SessionID">
                                    <Input onChange={(e) => setSessionID(e.target.value)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} xl={8}>
                                <Form.Item name="method" label="Method">
                                    <Input onChange={(e) => setMethod(e.target.value)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} xl={24}>
                                <Button type="primary" onClick={() => setRefreshData(refreshData + 1)}>
                                    Get logs <MonitorOutlined />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Card style={{ marginTop: "1em" }}>
                    <Row>
                        <Col xs={24} xl={24}>
                            <RequestLogsListTable
                                currentSessionID={props.sessionID}
                                minRequestSize={minRequestSize}
                                minResponseSize={minResponseSize}
                                minDuration={minDuration}
                                responseCode={responseCode}
                                method={method}
                                remoteAddr={remoteAddr}
                                endpoint={endpoint}
                                userID={userID}
                                sessionID={sessionID}
                                before={before}
                                after={after}
                                refreshData={refreshData}
                                setTotal={setTotal}
                            />
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
    );
};