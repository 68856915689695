import { removeToken } from "./userToken";

export const defDomain = "bh-admin.bangerhead.com"

export function getBackendLink() {
    let ret = "http://localhost:9090"
    if (process.env.REACT_APP_BACKEND_ADDR !== undefined && process.env.REACT_APP_BACKEND_ADDR !== "") {
        return process.env.REACT_APP_BACKEND_ADDR
    }

    return ret
}

export function getMicrosoftRediredURI() {
    let ret = "http://localhost:8080"
    if (process.env.REACT_APP_MICROSOFT_REDIRECT_URI !== undefined && process.env.REACT_APP_MICROSOFT_REDIRECT_URI !== "") {
        return process.env.REACT_APP_MICROSOFT_REDIRECT_URI
    }

    return ret
}

export function getMicrosoftClientID() {
    return process.env.REACT_APP_MICROSOFT_CLIENT_ID
}

export function getCookieDomain() {
    let ret = "localhost"
    if (process.env.REACT_APP_BACKEND_ADDR !== undefined && process.env.REACT_APP_BACKEND_ADDR !== "") {
        return "." + defDomain
    }

    return ret
}

export function actionOnError(err = '') {
    if (err === "bad_auth_token" || err === "access_token_invalid" || err === "invalid_session_marker") {
        removeToken()
        window.location.href = "/login"
    }

    if (err === "auth_required") {
        window.location.href = "/login"
    }
}

export function errorTranslation(err = '') {
    if (err === "not found") {
        return "Not found"
    }
    if (err === "admins_only") {
        return "You need to be an Administrator to view this page"
    }
    if (err === "user_not_exists") {
        return "Unknown user"
    }
    if (err === "sku_not_exists") {
        return "Product don't exist or doesn't have necessary data"
    }
    if (err === "auth_required") {
        return "Authorization needed"
    }
    if (err === "page_not_found") {
        return "Unknown API endpoint (contact IT department)"
    }
    if (err === "invalid_session_marker") {
        return "You need to relogin"
    }
    if (err === "Failed to fetch") {
        return "Error connecting to server"
    }
    if (err === "access_denied") {
        return "You have no access to this page"
    }
    if (err === "unknown_product") {
        return "Product don't exist or doesn't have necessary data"
    }
    if (err === "incorrect_ip_value") {
        return "Incorrect IP address value"
    }
    if (err === "no_products_under_conditions") {
        return "No products for these conditions"
    }
    if (err === "campaign_exists") {
        return "Campaign exists"
    }
    if (err === "ip_blocked") {
        return "IP address is blocked"
    }

    return err
}

export const MoreFloGroups = {
    "81024206-36b4-4d9e-a6f7-fa9641743b0c": "Exclude barcode sync",
    "3f5a51f5-2509-47d6-99cf-6ce15c61ffed": "Include barcode sync",
    "b0a043a3-8c8d-4ca0-ab28-88346917be8a": "Tjänst"
}

export const responseCodes = [
    {
        name: " ", code: 0,
    },
    {
        name: "200 OK", code: 200,
    },
    {
        name: "Bad Request 400", code: 400,
    },
    {
        name: "Unauthorized 401", code: 401,
    },
    {
        name: "Forbidden 403", code: 403,
    },
    {
        name: "Not found 404", code: 404,
    },
    {
        name: "Internal error 500", code: 500,
    },
]