import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Alert, Button, Col, Descriptions, Form, Row, Timeline, Tooltip, Typography, Input } from "antd";
import { PostRequestInternalAPI } from "../../../components/backend/postRequest";
import { BreadcrumbsEventsEvent } from "../../../components/breadcrumbs/breadcrumbsStat";
import { ErrorRow } from "../../../components/errorBlock";
import { IsCrititcal, IsDanger, IsError, IsWarning } from "../../../components/checkingValues";
import { EventLevelsMap, EventSourcesMap } from "../../../components/getEventsData";
import { TimeFromSeconds } from "../../../components/dateAndTime";
import {
    GetApplicationRunHref,
    GetApplicationRunLink,
    GetEventHref,
    GetUserLinkElement,
    GetUserLinkElementAvatar
} from "../../../components/getPageLinks";
import {
    GetEventCategories,
    GetEventSubCategories,
    TranslateCategory,
    TranslateSubCategory
} from "../../../components/getEventCategories";
import { FormatShortUUID } from "../../../components/formattingValues";
import { Spinner } from "../../../components/spinners";

const { TextArea } = Input;

export const Event = (props) => {
    const { id } = useParams();
    const [err, setErr] = useState("");
    const [data, setData] = useState({});
    const [trace, setTrace] = useState([]);
    const [payload, setPayload] = useState({});
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");

    const [form] = Form.useForm();
    const commentValue = Form.useWatch('comment', form);

    useEffect(() => {
        getEvent(id);
    }, [id]);

    const getEvent = (id) => {
        PostRequestInternalAPI(
            `/api/v1/notifications/events/get`, { event_id: id },
            setLoading,
            setErr,
            null,
        ).then((result = { data: {} }) => {
            if (result.data !== undefined && result.data !== null) {
                setData(result.data);
                if (result.data.EventPayload) {
                    const parsedPayload = JSON.parse(result.data.EventPayload);
                    setPayload(parsedPayload);
                    if (parsedPayload.trace) {
                        setTrace(parsedPayload.trace);
                    }
                }
            }
        });
    };

    const resolveEvent = (id) => {
        PostRequestInternalAPI(
            '/api/v1/notifications/events/resolve',
            {
                event_id: id,
                comment: commentValue,
                category: category,
                sub_category: subCategory,
            },
            null,
            null,
            null,
            props.messageAPI,
            { message: "Saving" }
        ).then(() => {
            setData({
                ...data,
                IsResolved: true,
                EventCategory: category,
                EventSubcategory: subCategory,
                ResolvedComment: commentValue,
                ResolvedAt: { seconds: Math.floor(Date.now() / 1000) },
                ResolvedByID: props.token.ID,
                ResolvedByAvatar: props.token.AvatarLink,
                ResolvedByName: props.token.Name,
            });
            props.recountEvents();
        });
    };

    if (err !== "") {
        return (
            <>
                <Helmet>
                    <title>{`Event #${id}`} || Bangerhead Admin</title>
                </Helmet>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <ErrorRow err={err} prefix={"Event"} />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

    if (loading === true) {
        return (
            <>
                <Helmet>
                    <title>{`Event #${id}`} || Bangerhead Admin</title>
                </Helmet>
                <Spinner />
            </>
        );
    }

    if (data.EventID === undefined) {
        return (
            <>
                <Helmet>
                    <title>{`Event #${id}`} || Bangerhead Admin</title>
                </Helmet>
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <ErrorRow err={"Event not found"} prefix={"Event"} />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>{`Event #${id}`} || Bangerhead Admin</title>
            </Helmet>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <BreadcrumbsEventsEvent loading={loading} id={id}
                            time={<TimeFromSeconds seconds={data.CreatedAt.seconds} />} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Typography.Title level={3} style={{ margin: 0 }}>
                    {data.Head}
                </Typography.Title>
                {EventSourcesMap[data.Source]?.tag || data.Source}
                {EventLevelsMap[data.Priority]?.tag || data.Priority}
                {TranslateCategory(data.EventCategory)}
                {TranslateSubCategory(data.EventSubcategory)}
            </div>
            {!data.IsResolved ? (
                <>
                    <div className="container-1700">
                        <Row>
                            <Col xs={24} xl={24}>
                                <Form disabled={loading} form={form} layout="vertical" autoComplete="off">
                                    <Form.Item name="comment" label="Comment">
                                        <TextArea rows={2} />
                                    </Form.Item>
                                    <Row gutter={16}>
                                        <Col xs={24} md={8}>
                                            <Form.Item name="Category" label="Category">
                                                <GetEventCategories setCategory={setCategory} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item name="Sub category" label="Sub category">
                                                <GetEventSubCategories setSubCategory={setSubCategory} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Tooltip placement="top" title={"Resolve event"}>
                                                <Button disabled={loading} className="button-primary success success-button"
                                                    type="primary"
                                                    onClick={() => resolveEvent(data.EventID)}>Resolve event</Button>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </>
            ) : (
                <div className="container-1700">
                    <Row>
                        <Col xs={24} xl={24}>
                            <Alert type="success" showIcon description={data.ResolvedComment}
                                message={<strong>Event resolved</strong>}
                                style={{ borderRadius: 0, margin: "1em 0 1em 0" }}
                            />
                        </Col>
                    </Row>
                </div>
            )}
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <Descriptions loading={loading}>
                            <Descriptions.Item label="Event ID">
                                <Typography.Text copyable={{ text: GetEventHref(data.EventID) }}>
                                    {FormatShortUUID(data.EventID)}
                                </Typography.Text>
                            </Descriptions.Item>
                            <Descriptions.Item label="Created">
                                <GetUserLinkElementAvatar id={data.CreatedBy} avatar={data.CreatedByAvatar} />
                                <GetUserLinkElement id={data.CreatedBy} name={data.CreatedByName} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Created at">
                                <TimeFromSeconds seconds={data.CreatedAt.seconds} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Run ID">
                                <Typography.Text copyable={{ text: GetApplicationRunHref(data.RunID) }}>
                                    {GetApplicationRunLink(data.RunID)}
                                </Typography.Text>
                            </Descriptions.Item>
                        </Descriptions>
                        {data.IsResolved && (
                            <Descriptions loading={loading}>
                                <Descriptions.Item label="Resolved">
                                    <GetUserLinkElementAvatar id={data.ResolvedByID} avatar={data.ResolvedByAvatar} />
                                    <GetUserLinkElement id={data.ResolvedByID} name={data.ResolvedByName} />
                                </Descriptions.Item>
                                <Descriptions.Item label="Resolved at">
                                    <TimeFromSeconds seconds={data.ResolvedAt.seconds} />
                                </Descriptions.Item>
                            </Descriptions>
                        )}
                    </Col>
                </Row>
            </div>

            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <GetAlertWithContent priority={data.Priority} content={data.Content} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <PrintPayload data={payload} />
                    </Col>
                </Row>
            </div>
            <div className="container-1700">
                <Row>
                    <Col xs={24} xl={24}>
                        <TraceList trace={trace} />
                    </Col>
                </Row>
            </div>
        </>
    );
};

const GetAlertWithContent = ({ priority, content }) => {
    if (IsWarning(priority)) {
        return <Alert message={content} type="warning" />;
    }

    if (IsError(priority)) {
        try {
            const stack = JSON.parse(content);
            const timeline = stack.Stack.flatMap(routine =>
                routine.Stack.map(call => ({
                    children: [`${call.File}:${call.Line}`],
                    label: call.Func,
                    color: 'red',
                }))
            );

            return (
                <>
                    <Alert type="error" showIcon description={stack.InitialError} message="Message"
                        style={{ backgroundColor: "#ff00001a", borderRadius: 0, margin: "1em 0 1em 0" }}
                    />
                    <Timeline mode="left" items={timeline} />
                </>
            );
        } catch (err) {
            console.log(err);
            return (
                <Alert type="error" showIcon description={content} message="Error"
                    style={{ backgroundColor: "#ff00001a", borderRadius: 0, margin: "1em 0 1em 0" }}
                />
            );
        }
    }

    if (IsDanger(priority) || IsCrititcal(priority)) {
        return <Alert message={content} type="error" />;
    }

    return content;
};

const PrintPayload = ({ data }) => (
    <Descriptions>
        {Object.keys(data).map(key => (
            key !== 'trace' && (
                <Descriptions.Item key={key} label={key}>
                    {JSON.stringify(data[key])}
                </Descriptions.Item>
            )
        ))}
    </Descriptions>
);

const TraceList = ({ trace }) => {
    const timeline = trace.map(routine => ({
        children: [routine],
        label: `${getLastElementInPath(routine)} ==>`,
        color: 'red',
    }));

    return <Timeline mode="left" items={timeline} />;
};

const getLastElementInPath = (path) => {
    const parts = path.split('/');
    return parts[parts.length - 1];
};