import { Select } from "antd";
import React from "react";
import { Tag } from "antd";

export const EventSourcesMap = {
    "": { id: "", name: "", tag: <Tag>???</Tag> },
    "bh_admin": { id: "bh_admin", name: "Admin", tag: <Tag>Admin</Tag> },
    "system": { id: "system", name: "System", tag: <Tag>System</Tag> },
    "auth_service": { id: "auth_service", name: "Auth Service", tag: <Tag>Auth Service</Tag> },
    "logger_service": { id: "logger_service", name: "Logger Service", tag: <Tag>Logger Service</Tag> },
    "notify_service": { id: "notify_service", name: "Notify Service", tag: <Tag>Notify Service</Tag> },
    "products_service": { id: "products_service", name: "Products Service", tag: <Tag>Products Service</Tag> },
    "eco_service": { id: "eco_service", name: "Ecosystem Service", tag: <Tag>Ecosystem Service</Tag> },
    "integration_more_flo": { id: "integration_more_flo", name: "MoreFlo integration", tag: <Tag>MoreFlo integration</Tag> },
    "integration_more_flo_receipts_import": { id: "integration_more_flo_receipts_import", name: "MoreFlo receipt import", tag: <Tag>MoreFlo receipt import</Tag> },
    "integration_slim4": { id: "integration_slim4", name: "Slim4 integration", tag: <Tag>Slim4 integration</Tag> },
    "integration_feeds_v2": {
        id: "integration_feeds_v2",
        name: "Feeds integration (v2)",
        tag: <Tag>Feeds integration (v2)</Tag>
    },
    "integration_gamifiera_user_feed_v2": {
        id: "integration_gamifiera_user_feed_v2",
        name: "Gamifiera users integration (v2)",
        tag: <Tag>Gamifiera users integration (v2)</Tag>
    },
    "integration_clickup": {
        id: "integration_clickup",
        name: "ClickUp integration",
        tag: <Tag>ClickUp integration</Tag>
    },
    "integration_replicator_v1": {
        id: "integration_replicator_v1",
        name: "Feed product replicator",
        tag: <Tag>Feed product replicator</Tag>
    },
    "replicator_voyado_uids": {
        id: "replicator_voyado_uids",
        name: "Voyado UIDs replicator",
        tag: <Tag>Voyado UIDs replicator</Tag>
    },
    "dev_debug": {
        id: "dev_debug",
        name: "(Developer) debug",
        tag: <><Tag color={"magenta"}>(Developer) debug</Tag></>
    },
    "cron_abpso_replication": {
        id: "cron_abpso_replication",
        name: "PriceSuggestionOffer",
        tag: <Tag>PriceSuggestionOffer</Tag>
    },
    "shelfless_replicator": {
        id: "shelfless_replicator",
        name: "Shelfless Replicator",
        tag: <Tag>Shelfless Replicator</Tag>
    },
    "bc_replicator": {
        id: "bc_replicator",
        name: "BC Replication",
        tag: <Tag>BC Replication</Tag>
    },
    "more_flo_salereport_import": {
        id: "more_flo_salereport_import",
        name: "MoreFlo SaleReport",
        tag: <Tag>MF SaleReport</Tag>
    },
    "askas_brand_import": {
        id: "askas_brand_import",
        name: "Askås Brand",
        tag: <Tag>Askås Brand</Tag>
    },
    "askas_country_import": {
        id: "askas_country_import",
        name: "Askås Country",
        tag: <Tag>Askås Country</Tag>
    },
    "askas_category_import": {
        id: "askas_category_import",
        name: "Askås Category",
        tag: <Tag>Askås Category</Tag>
    },
    "askas_currency_import": {
        id: "askas_currency_import",
        name: "Askås Currency",
        tag: <Tag>Askås Currency</Tag>
    },
    "askas_lang_import": {
        id: "askas_lang_import",
        name: "Askås Lang",
        tag: <Tag>Askås Lang</Tag>
    },
    "askas_domain_import": {
        id: "askas_domain_import",
        name: "Askås Domain",
        tag: <Tag>Askås Domain</Tag>
    },
    "askas_price_lowest_import": {
        id: "askas_price_lowest_import",
        name: "Askås Price Lowest",
        tag: <Tag>Askås Price Lowest</Tag>
    },
    "askas_price_rrp_import": {
        id: "askas_price_rrp_import",
        name: "Askås Price RRP",
        tag: <Tag>Askås Price RRP</Tag>
    },
    "askas_price_cached_import": {
        id: "askas_price_cached_import",
        name: "Askås Price Cached",
        tag: <Tag>Askås Price Cached</Tag>
    },
    "askas_price_currency_import": {
        id: "askas_price_currency_import",
        name: "Askås Price Currency",
        tag: <Tag>Askås Price Currency</Tag>
    },
    "askas_price_historical_import": {
        id: "askas_price_historical_import",
        name: "Askås Price Historical",
        tag: <Tag>Askås Price Historical</Tag>
    },
    "askas_voucher_code_import": {
        id: "askas_voucher_code_import",
        name: "Askås Voucher Code",
        tag: <Tag>Askås Voucher Code</Tag>
    },
    "askas_voucher_code_currency_import": {
        id: "askas_voucher_code_currency_import",
        name: "Askås Voucher Code Currency",
        tag: <Tag>Askås Voucher Code Currency</Tag>
    },
    "askas_voucher_code_product_import": {
        id: "askas_voucher_code_product_import",
        name: "Askås Voucher Code Product",
        tag: <Tag>Askås Voucher Code Product</Tag>
    },
    "askas_product_import": {
        id: "askas_product_import",
        name: "Askås Product",
        tag: <Tag>Askås Product</Tag>
    },
    "askas_product_set_import": {
        id: "askas_product_set_import",
        name: "Askås Product Set",
        tag: <Tag>Askås Product Set</Tag>
    },
    "askas_product_set_price_type_import": {
        id: "askas_product_set_price_type_import",
        name: "Askås Product Set Price Type",
        tag: <Tag>Askås Product Set Price Type</Tag>
    },
    "askas_product_text_import": {
        id: "askas_product_text_import",
        name: "Askås Product Text",
        tag: <Tag>Askås Product Text</Tag>
    },
    "askas_product_links_import": {
        id: "askas_product_links_import",
        name: "Askås Product Links",
        tag: <Tag>Askås Product Links</Tag>
    },
    "askas_product_inproductcategory_import": {
        id: "askas_product_inproductcategory_import",
        name: "Askås Product In Product Category",
        tag: <Tag>Askås Product In Product Category</Tag>
    },
    "askas_product_variantfeature_import": {
        id: "askas_product_variantfeature_import",
        name: "Askås Product Variant Feature",
        tag: <Tag>Askås Product Variant Feature</Tag>
    },
    "askas_product_stock_additional_location_import": {
        id: "askas_product_stock_additional_location_import",
        name: "Askås Product Stock Additional Location",
        tag: <Tag>Askås Product Stock Additional Location</Tag>
    },
    "askas_product_attributes_import": {
        id: "askas_product_attributes_import",
        name: "Askås Product Attributes",
        tag: <Tag>Askås Product Attributes</Tag>
    },
    "askas_product_attributes_field_import": {
        id: "askas_product_attributes_field_import",
        name: "Askås Product Attributes Field",
        tag: <Tag>Askås Product Attributes Field</Tag>
    },
    "askas_product_attributes_category_import": {
        id: "askas_product_attributes_category_import",
        name: "Askås Product Attributes Category",
        tag: <Tag>Askås Product Attributes Category</Tag>
    },
    "askas_product_attributes_value_import": {
        id: "askas_product_attributes_value_import",
        name: "Askås Product Attributes Value",
        tag: <Tag>Askås Product Attributes Value</Tag>
    },
    "askas_supplier_import": {
        id: "askas_supplier_import",
        name: "Askås Supplier",
        tag: <Tag>Askås Supplier</Tag>
    },
    "askas_stock_label_import": {
        id: "askas_stock_label_import",
        name: "Askås Stock Label",
        tag: <Tag>Askås Stock Label</Tag>
    },
    "askas_orders_import": {
        id: "askas_orders_import",
        name: "Askås Orders",
        tag: <Tag>Askås Orders</Tag>
    },
    "askas_order_reviews_import": {
        id: "askas_order_reviews_import",
        name: "Askås Order Reviews",
        tag: <Tag>Askås Order Reviews</Tag>
    },
    "askas_order_status_import": {
        id: "askas_order_status_import",
        name: "Askås Order Status",
        tag: <Tag>Askås Order Status</Tag>
    },
    "askas_order_address_import": {
        id: "askas_order_address_import",
        name: "Askås Order Address",
        tag: <Tag>Askås Order Address</Tag>
    },
    "askas_order_items_import": {
        id: "askas_order_items_import",
        name: "Askås Order Items",
        tag: <Tag>Askås Order Items</Tag>
    },
    "askas_order_shippingmethod_import": {
        id: "askas_order_shippingmethod_import",
        name: "Askås Order Shipping Method",
        tag: <Tag>Askås Order Shipping Method</Tag>
    },
    "askas_order_shippingmethodslimit_import": {
        id: "askas_order_shippingmethodslimit_import",
        name: "Askås Order Shipping Method Limit",
        tag: <Tag>Askås Order Shipping Method Limit</Tag>
    },
    "askas_order_settingsshippingmethodslimit_import": {
        id: "askas_order_settingsshippingmethodslimit_import",
        name: "Askås Order Settings Shipping Method Limit",
        tag: <Tag>Askås Order Settings Shipping Method Limit</Tag>
    },
    "askas_order_shippingmethodslimitvalues_import": {
        id: "askas_order_shippingmethodslimitvalues_import",
        name: "Askås Order Shipping Method Limit Values",
        tag: <Tag>Askås Order Shipping Method Limit Values</Tag>
    },
    "askas_customer_import": {
        id: "askas_customer_import",
        name: "Askås Customer",
        tag: <Tag>Askås Customer</Tag>
    },
    "askas_customer_active_cart_import": {
        id: "askas_customer_active_cart_import",
        name: "Askås Customer Active Cart",
        tag: <Tag>Askås Customer Active Cart</Tag>
    },
    "sitoo_orders_import": {
        id: "sitoo_orders_import",
        name: "Sitoo Orders",
        tag: <Tag>Sitoo Orders</Tag>
    },
    "sitoo_order_items_import": {
        id: "sitoo_order_items_import",
        name: "Sitoo Order Items",
        tag: <Tag>Sitoo Order Items</Tag>
    },

}

export const GetEventSourcesTag = (props) => {
    if (EventSourcesMap[props.source] === undefined) {
        return <Tag>{props.source}</Tag>
    }

    return EventSourcesMap[props.source].tag
}


export const EventSources = [
    { "id": "", "name": "" },
    { "id": "system", "name": "System" },
    { "id": "bh_admin", "name": "Admin" },
    { "id": "dev_debug", "name": "(Developer) debug" },

    { "id": "auth_service", "name": "Auth Service" },
    { "id": "eco_service", "name": "Ecosystem Service" },
    { "id": "logger_service", "name": "Logger Service" },
    { "id": "notify_service", "name": "Notify Service" },
    { "id": "products_service", "name": "Products Service" },

    { "id": "integration_more_flo", "name": "MoreFlo integration" },
    { "id": "integration_more_flo_receipts_import", "name": "MoreFlo receipt import" },
    { "id": "more_flo_salereport_import", "name": "MoreFlo SaleReport" },

    { "id": "sitoo_orders_import", "name": "Sitoo Orders Import" },
    { "id": "sitoo_order_items_import", "name": "Sitoo Order Items Import" },

    { "id": "integration_slim4", "name": "Slim4 integration" },

    { "id": "integration_clickup", "name": "ClickUp integration" },

    { "id": "integration_replicator_v1", "name": "Feed product replicator" },
    { "id": "replicator_voyado_uids", "name": "Voyado UIDs replicator" },
    { "id": "integration_feeds_v2", "name": "Feeds integration (v2)" },
    { "id": "integration_gamifiera_user_feed_v2", "name": "Gamifiera User Feed" },

    { "id": "cron_abpso_replication", "name": "PriceSuggestionOffer import" },

    { "id": "shelfless_replicator", "name": "Shelfless Replicator" },
    { "id": "bc_replicator", "name": "BC Replication" },

    { "id": "askas_brand_import", "name": "Askås Brand import" },
    { "id": "askas_country_import", "name": "Askås Country import" },
    { "id": "askas_category_import", "name": "Askås Category import" },
    { "id": "askas_currency_import", "name": "Askås Currency import" },
    { "id": "askas_lang_import", "name": "Askås Lang import" },
    { "id": "askas_domain_import", "name": "Askås Domain import" },

    { "id": "askas_price_lowest_import", "name": "Askås Price Lowest import" },
    { "id": "askas_price_rrp_import", "name": "Askås Price RRP import" },
    { "id": "askas_price_cached_import", "name": "Askås Price Cached import" },
    { "id": "askas_price_currency_import", "name": "Askås Price Currency import" },
    { "id": "askas_price_historical_import", "name": "Askås Price Historical import" },

    { "id": "askas_voucher_code_import", "name": "Askås Voucher Code import" },
    { "id": "askas_voucher_code_currency_import", "name": "Askås Voucher Code Currency import" },
    { "id": "askas_voucher_code_product_import", "name": "Askås Voucher Code Product import" },

    { "id": "askas_product_import", "name": "Askås Product import" },
    { "id": "askas_product_set_import", "name": "Askås Product Set import" },
    { "id": "askas_product_set_price_type_import", "name": "Askås Product Set Price Type import" },
    { "id": "askas_product_text_import", "name": "Askås Product Text import" },
    { "id": "askas_product_links_import", "name": "Askås Product Links import" },
    { "id": "askas_product_inproductcategory_import", "name": "Askås Product In Product Category import" },
    { "id": "askas_product_variantfeature_import", "name": "Askås Product Variant Feature import" },
    { "id": "askas_product_photo_import", "name": "Askås Product Photo import" },
    { "id": "askas_product_stock_additional_location_import", "name": "Askås Product Stock Additional Location import" },
    { "id": "askas_product_attributes_import", "name": "Askås Product Attributes import" },
    { "id": "askas_product_attributes_field_import", "name": "Askås Product Attributes Field import" },
    { "id": "askas_product_attributes_category_import", "name": "Askås Product Attributes Category import" },
    { "id": "askas_product_attributes_value_import", "name": "Askås Product Attributes Value import" },

    { "id": "askas_supplier_import", "name": "Askås Supplier import" },

    { "id": "askas_stock_label_import", "name": "Askås Stock import" },

    { "id": "askas_orders_import", "name": "Askås Order import" },
    { "id": "askas_order_reviews_import", "name": "Askås Order Reviews import" },
    { "id": "askas_order_status_import", "name": "Askås Order Status import" },
    { "id": "askas_order_address_import", "name": "Askås Order Address import" },
    { "id": "askas_order_items_import", "name": "Askås Order Items import" },
    { "id": "askas_order_shippingmethod_import", "name": "Askås Order Shipping Method import" },
    { "id": "askas_order_shippingmethodslimit_import", "name": "Askås Order Shipping Method Limit import" },
    { "id": "askas_order_settingsshippingmethodslimit_import", "name": "Askås Order Settings Shipping Method Limit import" },
    { "id": "askas_order_shippingmethodslimitvalues_import", "name": "Askås Order Shipping Method Limit Values import" },

    { "id": "askas_customer_import", "name": "Askås Customer import" },
    { "id": "askas_customer_active_cart_import", "name": "Askås Customer Active Cart import" },
]

export const GetEventSourcesSelect = (props) => {
    return (
        <Select
            showSearch
            onChange={(value) => {
                props.onChange(value);
            }}
            options={EventSources}
            fieldNames={{ label: "name", value: "id" }}
            filterOption={(input, option) =>
                option?.name?.toLowerCase().includes(input.toLowerCase())
            }
        />
    )
}

export const EventLevelsMap = {
    "": { id: "", name: "", tag: <Tag>???</Tag> },
    "info": { id: "info", name: "Info", tag: <Tag>Info</Tag> },
    "warning": { id: "warning", name: "Warning", tag: <Tag color="gold">Warning</Tag> },
    "error": { id: "error", name: "Error", tag: <Tag color="volcano">Error</Tag>, },
    "danger": { id: "danger", name: "Danger", tag: <Tag color="red">Danger</Tag> },
    "critical": { id: "critical", name: "Critical", tag: <Tag color="#f50">Critical</Tag> },
}

export const EventLevels = [
    { "id": "", "name": "" },
    { "id": "info", "name": "Info" },
    { "id": "warning", "name": "Warning" },
    { "id": "error", "name": "Error" },
    { "id": "danger", "name": "Danger" },
    { "id": "critical", "name": "Critical" },
]

export const GetEventLevelsSelect = (props) => {
    return (
        <Select
            onChange={(value) => {
                props.onChange(value)
            }}
            options={EventLevels}
            fieldNames={{ label: "name", value: "id" }}
        >
        </Select>
    )
}
